import * as THREE from 'three';
// import gsap from "gsap";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
// import { TweenMax, Power2 } from "gsap";

import TweenMax from 'TweenMax';
import Power2 from 'Power2';

import { IModel, ModelFactory } from './classes/Model';

// gsap.TweenMax
// gsap.Power2

class Venues {
  // elements
  container: HTMLElement;
  containerRect: DOMRect;
  infoPElement: HTMLElement;

  // webgl
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  loader: GLTFLoader;
  models: IModel[];
  spotlight: THREE.SpotLight;

  // parameters
  distance: number = 15.5;
  activeModel: number = 0;

  constructor(containerId?: string) {
    this.container = document.getElementById(containerId) || document.body;
    let venuesScene = document.getElementById('venues-scene');
    venuesScene.style.height = venuesScene.getBoundingClientRect().width +"px";

    this.containerRect = this.container.getBoundingClientRect();
    this.infoPElement = document.getElementById('infovenues');
    this.init();
  }

  init() {
    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2(0x000000, 0.07);

    this.camera = new THREE.PerspectiveCamera(80, this.containerRect.width / this.containerRect.height, 0.1, 2000);
    this.camera.position.set(0, 0, 6.5);

    this.renderer = new THREE.WebGLRenderer({
      'alpha': true,
      'antialias': true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.containerRect.width, this.containerRect.height);
    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.toneMappingExposure = Math.pow(0.74, 5.0);

    this.renderer.gammaFactor = 1.5;
    this.container.appendChild(this.renderer.domElement);
    this.loader = new GLTFLoader();
    this.models = this.getModelsList();
    this.sceneSetup();
    this.addEventListeners();
  }

  getModelsList = () => {
    const mf = new ModelFactory();

    // mf.setBaseURL('./public/gltfs/');
    mf.setBaseURL('./wp-content/themes/hello-theme-child-master/venues/public/gltfs/');
    mf.addModel(
      'zapeion_model/zappeion_model_ff.glb',
      `<span class='venuetitle'>ZAPPEION MEGARON</span><br/> in Athens is a part of national heritage of Greek civilization. It is designed by the famous Danish architect Theophil Freiherr von Hansen in 1878 and is a part of national heritage of Greek civilization. The Zappeion Megaron is named after Evangelos Zappas, a man from Epirus who envisaged the rebirth of the spirit of ancient Greece and devoted his life to the revival of the Olympic Games and the promotion of the Αrts. The Zappeion building is a tribute to this great man who brought the Olympic Games back into the modern world. The Zappeion Megaron is easily accessible with a short walk from Syntagma Square metro station. </br>Address: Queen Olga's, Av., Athens 105 57`,
      [
        { pointLight: new THREE.PointLight(0xffffff, 6, 16), from: new THREE.Vector3(-1, 1.1, 0.6), to: new THREE.Vector3(1, 0.8, 0.6), duration: 3 },
        { pointLight: new THREE.PointLight(0x003366, 40, 4), from: new THREE.Vector3(0, -1, 1), to: new THREE.Vector3(2.7, 2.6, 1), duration: 2 },
        { pointLight: new THREE.PointLight(0x003366, 40, 4), from: new THREE.Vector3(-3, -1, 1), to: new THREE.Vector3(0.7, -2.6, 1), duration: 4 },
      ]);
    mf.addModel(
      'benaki_model/benaki_model_ftlast.glb',
      `<span class='venuetitle'>BENAKI MUSEUM</span><br/> of Greek Culture is housed in one of the most beautiful neoclassical-style buildings in Athens, near the National Garden and Syntagma Square. Following its most recent refurbishment (1989–2000), the building houses a unique exhibition on Greek culture arranged diachronically from prehistory to the 20th century. The Benaki Museum is easily accessible with a short walk from Syntagma Square metro station. </br>Address: 1 Koumbari St. & Vas. Sofias Ave., 106 74 Athens`,
      [
        { pointLight: new THREE.PointLight(0xffffff, 6, 16), from: new THREE.Vector3(2, 2.6, 0.6), to: new THREE.Vector3(-2, 2.2, 0.6), duration: 3 },
        { pointLight: new THREE.PointLight(0x003366, 40, 4), from: new THREE.Vector3(0, -1, 1), to: new THREE.Vector3(2.7, 2.6, 1), duration: 2 },
        { pointLight: new THREE.PointLight(0x003366, 40, 4), from: new THREE.Vector3(-3, -1, 1), to: new THREE.Vector3(0.7, -2.6, 1), duration: 4 },
      ]);
    mf.addModel(
      'diplarios_model/diplarios_model_blend.glb',
      `<span class='venuetitle'>DIPLAREIOS SCHOOL</span><br/> is one of the few examples of 1930's Greek modernist Architecture stilll standing and it is a private, non-profit organization that provides free education and training. It was founded by Professor in the University of Athens Kiparissos Stefanos, who wanted to establish an educational organization where Greek children will have free vocational studies. It has seen many changes in use, operationg as a school for manufacturers and craft makers, city-planning offices and a nursery school. The building, representing the obsolescence of its manufacturing sector, was chosen to illustrate the historiography of the Greek economy. </br>Address: 3 Theatrou Square, 105 52 Athens`,
      [
        { pointLight: new THREE.PointLight(0xffffff, 6, 16), from: new THREE.Vector3(2, 2.6, 0.6), to: new THREE.Vector3(-2, 2.2, 0.6), duration: 3 },
        { pointLight: new THREE.PointLight(0x003366, 40, 4), from: new THREE.Vector3(0, -1, 1), to: new THREE.Vector3(2.7, 2.6, 1), duration: 2 },
        { pointLight: new THREE.PointLight(0x003366, 40, 4), from: new THREE.Vector3(-3, -1, 1), to: new THREE.Vector3(0.7, -2.6, 1), duration: 4 },
      ]);


    return mf.getList();
  }

  sceneSetup = () => {
    this.models.forEach(this.loadModel);
    this.setupSpotLight();
    this.activeModel = 0;
    this.matchInfoToModel();
  }

  loadModel = (model: IModel, index: number) => {
    let self = this;
    this.loader.load(model.url, function (data) {
      let group = new THREE.Group();
      group.position.set(index * self.distance, 1.2, 0);

      let object = data.scene;
      group.add(object);

      TweenMax.from(group.position, 2, {
        y: 0.6,
        yoyo: true,
        repeat: -1,
        ease: Power2.easeInOut
      });

      model.lights.forEach((light) => {
        group.add(light.pointLight);
        light.pointLight.position.set(light.from.x, light.from.y, light.from.z);
        TweenMax.from(light.pointLight.position, light.duration, {
          x: light.to.x,
          y: light.to.y,
          z: light.to.z,
          yoyo: true,
          repeat: -1,
          ease: Power2.easeInOut
        });

      });

      self.scene.add(group);
      self.models[index].group = group;
    });
  }

  setupSpotLight = () => {
    this.spotlight = new THREE.SpotLight(0xffffff, 5, 0, Math.PI / 5, 0.35);
    this.spotlight.position.set(0, 0, 4.5);
    this.spotlight.target.position.set(0, 0, 0);

    this.spotlight.castShadow = true;
    this.spotlight.shadow.mapSize.width = 1024;
    this.spotlight.shadow.mapSize.height = 1024;
    this.spotlight.shadow.camera.near = 500;
    this.spotlight.shadow.camera.far = 4000;
    this.spotlight.shadow.camera.fov = 30;

    this.scene.add(this.spotlight);
    this.scene.add(this.spotlight.target);
  }

  matchInfoToModel() {
    this.infoPElement.style.display = 'none';
    this.infoPElement.innerHTML = this.models[this.activeModel].desc;
  }

  addEventListeners() {
    // canvas resize
    window.addEventListener('resize', this.resize.bind(this), false);
    // move spotlight towards mouse position
    window.addEventListener('mousemove', this.mouseMove.bind(this), false);
    // Buttons' events
    const leftArrow = document.getElementById('ctrlout');
    leftArrow.addEventListener('click', this.moveLeft.bind(this), false);
    const rightArrow = document.getElementById('ctrlin');
    rightArrow.addEventListener('click', this.moveRight.bind(this), false);
    const infoButton = document.getElementById('thisinfo');
    infoButton.addEventListener('click', this.toggleInfo.bind(this), false);
  }

  resize = () => {
    let venuesScene = document.getElementById('venues-scene');
    venuesScene.style.height = venuesScene.getBoundingClientRect().width +"px";

    this.containerRect = this.container.getBoundingClientRect();
    this.camera.aspect = this.containerRect.width / this.containerRect.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.containerRect.width, this.containerRect.height);
  }

  mouseMove(_event: MouseEvent) {
    let mouse = { x: 0, y: 0 };
    _event.preventDefault();
    mouse.x = (_event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(_event.clientY / window.innerHeight) * 2 + 1;

    // Make the sphere follow the mouse
    let vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
    vector.unproject(this.camera);
    let dir = vector.sub(this.camera.position).normalize();
    let distance = -this.camera.position.z / dir.z;
    let pos = this.camera.position.clone().add(dir.multiplyScalar(distance));
    this.spotlight.target.position.copy(pos);
  }

  moveLeft = (_event) => {
    _event.preventDefault();
    let dir: number = -1;
    let self = this;
    this.changeModel(self, dir);
  }

  moveRight = (_event) => {
    _event.preventDefault();
    let dir: number = 1;
    let self = this;
    this.changeModel(self, dir);
  }

  changeModel(self: this, dir: number) {
    self.activeModel += dir;
    self.activeModel %= self.models.length;
    self.activeModel = (self.activeModel < 0) ? self.models.length - 1 : self.activeModel;

    self.matchInfoToModel();

    // reposition camera
    TweenMax.to(self.camera.position, 1, { x: self.activeModel * self.distance, ease: Power2.easeInOut });
    //reposition spotlight and spotlight target
    TweenMax.to(self.spotlight.position, 1, { x: self.activeModel * self.distance, ease: Power2.easeInOut });
    TweenMax.to(self.spotlight.target.position, 1, { x: self.activeModel * self.distance, ease: Power2.easeInOut });
  }

  toggleInfo() {
    this.infoPElement.style.display = (this.infoPElement.style.display == 'block') ? 'none' : 'block';
  }

  public render = () => {
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.render);
  }
}

const venuesApp = new Venues('venue-app');
venuesApp.render();