export interface IMyPointLight {
    pointLight: THREE.PointLight
    from: THREE.Vector3
    to: THREE.Vector3
    duration: number
}

export interface IModel {
    url: string
    desc: string
    lights?: IMyPointLight[]
    group?: THREE.Group
}

export class ModelFactory {
    baseURL: string;
    list: IModel[] = [];

    public setBaseURL = (baseURL: string = './') => {
        this.baseURL = baseURL;
        this.baseURL += this.baseURL.endsWith("/") ? "" : "/";
    }

    public addModel = (model: string, desc: string, lights?: IMyPointLight[]) => {
        this.list.push({ url: this.baseURL + model, desc: desc, lights: lights });
    }
    public getList= () => {
        return this.list;
    } 
}